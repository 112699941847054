<template>
  <v-container>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>{{ $vuetify.lang.t("$vuetify.studentResult") }}</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-form @submit.prevent="options.search = searchForm">
          <v-text-field
            v-model="searchForm"
            @click:append="clearSearch"
            :append-icon="searchForm ? 'mdi-close' : ''"
            prepend-icon="mdi-magnify"
            :label="$vuetify.lang.t('$vuetify.search')"
            single-line
            hide-details
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <data-table
      :headers="headers"
      :items="students"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          first-icon="mdi-chevron-double-left"
          last-icon="mdi-chevron-double-right"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          :items-per-page-options="[25, 50, 100, -1]"
          :options="options"
          :pagination="pagination"
          :show-first-last-page="true"
          :show-current-page="true"
        />
      </template>
      <template v-slot:[`item.schoolName`]="{ item: { schoolName } }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              @click="
                searchForm = schoolName;
                options.search = schoolName;
                options.page = 1;
              "
              class="pointer"
              >{{ schoolName }}</span
            >
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.filterSchool") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.groupName`]="{ item: { groupName } }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              @click="
                searchForm = groupName;
                options.search = groupName;
                options.page = 1;
              "
              class="pointer"
              >{{ groupName }}</span
            >
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.filterGroup") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.action`]="{ item: { id } }">
        <div style="width: 100px;">
          <v-btn
            color="primary"
            :to="{
              name: 'Result.Student.TestResult',
              params: { student_id: id }
            }"
            class="mt-1 mb-2"
            medium
            block
            >{{ $vuetify.lang.t("$vuetify.result") }}</v-btn
          >
        </div>
      </template>
    </data-table>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import Student from "@/services/student";

export default {
  name: "Result.Student.Index",
  data() {
    return {
      totalData: 0,
      students: [],
      loading: true,
      options: {
        search: undefined
      },
      headers: [
        { text: "User", value: "userName" },
        { text: "School", value: "schoolName" },
        { text: "Group", value: "groupName" },
        { text: "Average Score", value: "averageScore" },
        { text: "Action", value: "action" }
      ],
      searchForm: null
    };
  },
  watch: {
    options: {
      handler() {
        this.getStudents();
      },
      deep: true
    }
  },
  components: {
    DataTable
  },
  created() {
    this.setNavbarTitle("Manage Student");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getStudents() {
      this.loading = true;
      this.students = [];
      const students = await Student.index({
        ...this.options,
        sortBy: "userName",
        score: true
      });
      this.students = students.data.data.students;
      this.totalData = students.data.data.total_data;
      this.loading = false;
    },
    clearSearch() {
      this.options.search = undefined;
      this.searchForm = null;
    }
  }
};
</script>

<style scoped>
.pointer:hover {
  cursor: pointer;
}
</style>
